<template>
  <div v-loading="formLoading">
    <div class="card mb-5 mb-xl-10" v-if="pressID">
      <div class="card-header border-0 pt-6">
        <div class="d-flex flex-column">
          <div class="d-flex align-items-center mb-2 float-left">
            <span class="text-gray-900 fs-2 fw-bolder me-1">{{ oldPressData.name }}</span>
          </div>
          <div class="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2 float-left">
            <span class="d-flex align-items-center text-gray-400 me-5 mb-2" v-html="oldPressData.description.length > 500 ? oldPressData.description.substring(0,500)+'...' : oldPressData.description"></span>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header border-0 pt-6">
        <el-form
          :model="formData"
          :rules="rules"
          ref="formRef"
          class="w-100"
        >
          <div class="row g-9">
            <div class="col-md-6 fv-row">
              <div class="mb-5">
                <label class="required fs-6 fw-bold mb-2">{{constants.presses.PRESS_NAME}}</label>
                <el-form-item prop="name">
                  <el-input size="large"
                    v-model="formData.name"
                    type="text"
                    :placeholder="constants.presses.PRESS_NAME"
                  />
                </el-form-item>
              </div>
            </div>
          </div>
          <div class="row g-9">
            <div class="col-md-6 fv-row mb-5">
              <div class="mb-5">
                <label class="required fs-6 fw-bold mb-2">{{constants.presses.PRESS_DESCRIPTION}}</label>
                <el-form-item prop="description">
                  <el-input size="large"
                    v-model="formData.description"
                    type="textarea"
                    rows="5"
                    :placeholder="constants.presses.PRESS_DESCRIPTION"
                  />
                </el-form-item>
              </div>
            </div>
          </div>
          <div class="row g-9">
            <div class="col-md-12 mb-5 mt-12">
                <button type="button" class=" btn btn-primary me-2" v-if="((permission.isEditAllowed) || (permission.isAddAllowed && permission.isEditAllowed))" :disabled="loading" @click="submitForm(formRef, 'save')">{{constants.general.SAVE}}<span v-if="loading" class="spinner-border spinner-border-sm align-middle ms-2"></span>
                </button>
                <button type="button" class=" btn btn-primary me-2" :disabled="loadingback" @click="submitForm(formRef, 'save_back')">{{constants.general.SAVEANDBACK}}<span v-if="loadingback" class="spinner-border spinner-border-sm align-middle ms-2"></span></button>
                <button type="button" class=" btn btn-secondary me-2" @click.prevent="$router.push({ name: 'presses'})">{{constants.general.CANCEL}}</button>
            </div>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref, onMounted, onUpdated } from 'vue';
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import { useRoute,useRouter } from 'vue-router'
import { notificationFire } from "@/composable/notification.js";
import globalConstant from "@/core/data/globalConstant.js";
import { formatText } from "@/core/helpers/common";
import useEventBus from '../../composable/useEventBus'
import { setCurrentPageActionButtons } from "@/core/helpers/actionButtons";
import { loggedInUserRoles, loggedInUserPermissions } from "@/composable/get-roles.js";
export default {
  name: "add-press",
  setup() {
    const { onEvent, emitEvent } = useEventBus()
    const formRef = ref(null);
    const loading = ref(false);
    const loadingback = ref(false);
    const pressID = ref(0);
    const route = useRoute();
    const router = useRouter();
    const constants = globalConstant;
    const formLoading = ref(false);
    const oldPressData = reactive({
      name: '',
      description: ''
    });
    const formData = reactive({
      name: "",
      description: "",
    });
    const permission = reactive({
      isAddAllowed: false,
      isEditAllowed: false,
    })
    let redirect = ref(false);
    //Get click event of toolbar buttons
    onEvent('actionName', (actionName) => {
      if(actionName == "save"){
        submitForm(formRef, 'save', true)
      }else if(actionName=="save_back"){
        submitForm(formRef, 'save_back',true)
      }else if(actionName == "cancel"){
        router.push({ name: 'presses'});
      }
    })
    const rules = ref({
      name: [
        {
          required: true,
          message: "Press name is required",
          trigger: "change",
        },
      ],
      description: [
        {
          required: true,
          message: "Description is required",
          trigger: "change",
        },
        {
          min: 8,
          message: "Please choose a description with at least 8 characters",
          trigger: "change",
        },
      ],
    });
    
    const submitForm = async (formEl, btnName, isToolbarButtonsSubmitAction=false) => {
      formEl = (isToolbarButtonsSubmitAction) ? formEl.value : formEl;
      if (!formEl) return
      await formEl.validate((valid, fields) => {
        if (valid) {
          (isToolbarButtonsSubmitAction) ? emitEvent('loader',{"save_loader":true,"action":btnName}) : (btnName == "save")?(loading.value = true) : (loadingback.value = true);
          let requestUrl = 'presses';
          if (pressID.value > 0) {
            requestUrl = 'presses/'+pressID.value;
            formData._method = "PUT";
          }
          ApiService.post(requestUrl, formData)
          .then((response) => {
            if (response.data) {
              let status = response.status;
              let message = response.data.message;
              if (status == 200 || status == 201) {
                oldPressData.name = formData.name;
                oldPressData.description = formData.description;
                if (pressID.value == 0 || pressID.value === undefined) pressID.value = response.data.data.id;
                notificationFire(message,'success');
                if (btnName == 'save') {
                  (isToolbarButtonsSubmitAction) ? emitEvent('loader',{"save_loader":false,"action":btnName}) : (btnName == "save")?(loading.value = false) : (loadingback.value = false);
                  if (typeof route.params.id === "undefined") {
                    setCurrentPageBreadcrumbs(formatText('edit-press'), [{"name" :"Presses", 'path': 'presses'}, {"name": formatText('edit-press') , "active": true}]);
                    router.push({ name: 'edit-press', params: { id: pressID.value } })
                  }
                } else if (btnName == 'save_back') {
                  router.push({ name: 'presses'})
                }
              }
              else
              {
                notificationFire(message,'error');
              }
            }
          })
          .catch((error) => {
            (isToolbarButtonsSubmitAction) ? emitEvent('loader',{"save_loader":false,"action":btnName}) : (btnName == "save")?(loading.value = false) : (loadingback.value = false);
            let message = error.message;
            notificationFire(message,'error');
          });
        }
      })
    }
    
    //Get all presses
    function getPressData() {
      formLoading.value = true;
      ApiService.query("presses/" + pressID.value)
      .then(({ data }) => {
        if (data.status) {
            formData.name = oldPressData.name = data.data.name;
            formData.description = oldPressData.description = data.data.description;
        }
        else
        {
          formData.name = '';
          formData.description = '';
        }
        formLoading.value = false;
      })
      .catch((error) => {
        formLoading.value = false;
        let response = error.response;
        formData.name = '';
        formData.description = '';
        if (response) {
          let status = response.status;
          let message = response.data.message;
          notificationFire(message, "error");
          if (status == 404) {
            router.push({ name: 'presses'})
          }
        }
      });
    }
    //Generate Current Page Toolbar Dynamic Buttons
    function generateHeaderToolbarButtons(){
      return setCurrentPageActionButtons([{"name" :constants.general.SAVE, 'action': 'save','class': 'btn-primary me-3','isLoader': false,'isAllowed': (permission.isEditAllowed) || (permission.isAddAllowed && permission.isEditAllowed)}, {"name": constants.general.SAVEANDBACK , 'action': 'save_back','class': 'btn-primary me-3','isLoader':false }, {"name": constants.general.CANCEL , 'action': 'cancel','class': 'btn-secondary','isLoader':false }]);
    }
    //Set breadcrumbs
    onMounted(async () => {
      setCurrentPageBreadcrumbs(formatText(route.name), [{"name" :"Presses", 'path': 'presses'}, {"name": formatText(route.name) , "active": true}]);
      let role = loggedInUserRoles()
      let per = loggedInUserPermissions();
      if (per.includes("createPress") || role == "Super Admin")
        permission.isAddAllowed = true;
      if (per.includes("updatePress") || role == "Super Admin")
        permission.isEditAllowed = true;

      if (route.params.id != "undefined")
      { pressID.value =  route.params.id; }
      
      if (pressID.value > 0) {
        await getPressData();
      }
      generateHeaderToolbarButtons();
    })
    onUpdated(async () => {
      generateHeaderToolbarButtons();
    })
    return {
      formData,
      rules,
      submitForm,
      formRef,
      loading,
      loadingback,
      redirect,
      constants,
      formLoading,
      permission,
      pressID,
      oldPressData
    };
  },
};
</script>